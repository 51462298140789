<template>
  <div>

  </div>
</template>

<script>
export default {
  name: "DownloadAgreement",
  mounted() {
    this.download();
  },
  methods: {
    async download() {

    }
  }
}
</script>

<style scoped>

</style>
